<script>
	import Papa from "papaparse";

	let totalVaccinations = 0;

	// URL of the CSV file
	const csvUrl =
		"https://opendata-files.sozialversicherung.at/eimpfpass/immunization_doses_v202310.csv?q=now";

	// Helper function to get date from ISO week number
	function getDateFromWeek(year, week) {
		const simple = new Date(year, 0, 1 + (week - 1) * 7);
		const dow = simple.getDay();
		const ISOweekStart = simple;
		if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
		else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
		return ISOweekStart;
	}

	// Load and process the CSV data
	Papa.parse(csvUrl, {
		download: true,
		header: true,
		complete: (results) => {
			// Filter records for COVID-19 and from September 1st, 2024 onwards
			const filteredData = results.data.filter((row) => {
				const immunizationTarget = row["immunization_target"]; // Adjust if needed
				const year = parseInt(row["year"], 10);
				const week = parseInt(row["week"], 10);

				// Skip if immunization_target is not COVID-19 or year/week is invalid
				if (immunizationTarget !== "SARS-CoV-2" || isNaN(year) || isNaN(week)) {
					return false;
				}

				// Convert year and week to a date
				const date = getDateFromWeek(year, week);

				// Check if the date is after September 1st, 2024
				return date >= new Date("2024-09-01");
			});

			// Sum up the doses administered
			totalVaccinations = filteredData.reduce((sum, row) => {
				const doses = parseInt(row["doses_administered"], 10); // Adjust if needed
				return sum + (isNaN(doses) ? 0 : doses);
			}, 0);
		},
		error: (err) => {
			console.error("Error parsing CSV:", err);
		},
	});
</script>

{#if totalVaccinations > 0}
	<div class="md:flex md:space-x-4">
		<p class="text-6xl font-light">
			{totalVaccinations.toLocaleString("de-DE")}
		</p>
		<div class="">
			<p>im e-Impfpass dokumentierte Impfungen seit 1. September 2024</p>
			<p class="underline underline-offset-4 opacity-70">
				<a href="https://impfdaten.at">Impfdaten-Dashboard</a>
			</p>
		</div>
	</div>
{/if}

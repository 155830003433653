<script>
	import { onMount } from "svelte";
	import Papa from "papaparse";

	let data = [];
	let searchQuery = "";
	let filteredData = [];

	// Function to load and parse the CSV data
	async function loadCSV() {
		const response = await fetch("/directory");
		const csvText = await response.text();

		Papa.parse(csvText, {
			header: true,
			complete: (results) => {
				data = results.data;
				filteredData = data;
			},
		});
	}

	// Load the CSV data when the component mounts
	onMount(() => {
		loadCSV();
	});

	// Update the filtered data whenever the search query changes
	$: filteredData = data.filter((item) => {
		const query = searchQuery.toLowerCase();
		return (
			(item.PLZ && item.PLZ.toLowerCase().includes(query)) ||
			(item["Stadt/Ort"] && item["Stadt/Ort"].toLowerCase().includes(query)) ||
			(item.Ordination && item.Ordination.toLowerCase().includes(query))
		);
	});
</script>

<div class="relative">
	<input
		class="appearance-none bg-gray-100 py-2 px-4 rounded-2xl w-full text-lg"
		type="text"
		placeholder="Nach PLZ, Ort oder Namen suchen"
		bind:value={searchQuery}
	/>

	{#if searchQuery}
		<div class="h-80 mt-4 overflow-scroll pb-20">
			{#if filteredData.length > 0}
				<ul class="">
					{#each filteredData.sort((a, b) => a.Ordination > b.Ordination) as doctor}
						<li class="my-2 bg-currentvac bg-opacity-20 p-4 rounded-2xl">
							<h3 class="text-xl">
								{doctor.Ordination}
								<span class="opacity-70">&bull; {doctor.Fachrichtung}</span>
							</h3>
							<div>{doctor.PLZ} {doctor["Stadt/Ort"]}, {doctor.Straße}</div>
						</li>
					{/each}
				</ul>
			{:else}
				<p>
					Keine Einträge für {searchQuery} gefunden. Probieren Sie eine andere PLZ/Ort
					in der Nähe aus.
				</p>
			{/if}
			<div
				class="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-b from-transparent to-white"
			></div>
		</div>
	{/if}
</div>

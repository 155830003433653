import InfluenzaCases from "./InfluenzaCases.svelte";
import InfluenzaVaccinations from "./InfluenzaVaccinations.svelte";
import COVID19Vaccinations from "./COVID19Vaccinations.svelte";
import Search from "./Search.svelte";

if (document.getElementById("InfluenzaCases")) {
  const i1 = new InfluenzaCases({
    target: document.getElementById("InfluenzaCases"),
  });
}

if (document.getElementById("InfluenzaVaccinations")) {
  const i2 = new InfluenzaVaccinations({
    target: document.getElementById("InfluenzaVaccinations"),
  });
}

if (document.getElementById("COVID19Vaccinations")) {
  const i3 = new COVID19Vaccinations({
    target: document.getElementById("COVID19Vaccinations"),
  });
}

if (document.getElementById("Search")) {
  const i4 = new Search({
    target: document.getElementById("Search"),
  });
}

<script>
	const data = [
		{
			season: "2015/2016",
			deaths: 492,
		},
		{
			season: "2016/2017",
			deaths: 4939,
		},
		{
			season: "2017/2018",
			deaths: 4277,
		},
		{
			season: "2018/2019",
			deaths: 2022,
		},
		{
			season: "2019/2020",
			deaths: 1714,
		},
		{
			season: "2020/2021",
			deaths: 0,
		},
		{
			season: "2021/2022",
			deaths: 652,
		},
		{
			season: "2022/2023",
			deaths: 4020,
		},
	];

	let chartWidth;
	let chartHeight;

	const yScale = function (value) {
		return (value / 7000) * chartHeight;
	};

	$: selected = data[data.length - 1];
</script>

<div class="flex items-baseline mt-2">
	<p class="text-6xl font-light w-32">
		{selected.deaths.toLocaleString("de-DE")}
	</p>
	<div id="chart" bind:clientWidth={chartWidth} bind:clientHeight={chartHeight}>
		{#if chartWidth && chartHeight}
			{#each data as point}
				<div class="relative bar">
					<div
						style="height: {yScale(point.deaths)}px"
						class="absolute bottom-0 left-0 right-0 bg-black {selected == point
							? 'opacity-100'
							: 'opacity-50'}"
					/>
					<div
						style="height: {chartHeight}px"
						class="absolute w-full h-full bg-black bg-opacity-0"
						on:mouseover={() => (selected = point)}
						on:focus={() => (selected = point)}
					/>
				</div>
			{/each}
		{/if}
	</div>
</div>

<p>geschätzte Todesfälle durch Influenza in der Saison {selected.season}</p>
<p class="opacity-70 underline underline-offset-4">
	<a
		href="https://www.ages.at/mensch/krankheit/krankheitserreger-von-a-bis-z/grippe"
		class="underline">AGES</a
	>: Geschätzte Anzahl der Todesfälle, jeweils KW 40-KW 20 des Folgejahres
</p>

<style>
	.bar {
		width: 20px;
		height: 100%;
	}

	#chart {
		height: 3em;
		width: 10em;
		display: flex;
		align-items: baseline;
		gap: 2px;
	}
</style>
